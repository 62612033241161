import {
  deleteAppData,
  getAppData,
  postAppData,
  putAppData,
  TAGS_APP,
} from "../../../utils/api/apps"
import { ITag, ITagGroup } from "../types"

export const getTags = async (space?: string) => {
  return await getAppData(TAGS_APP, `${space ? `?space=${space}` : ""}`)
}

export const getTagGroups = async (space?: string | undefined) => {
  let queryString: string
  if (space) {
    queryString = `?space=${space}`
  }
  if (queryString == undefined) {
    queryString = ""
  }
  const tagGroups = await getAppData(TAGS_APP, `/groups${queryString}`)
  return tagGroups
}

export const getTagsBySources = async (
  sources: string[],
  params?: { space?: string },
) => {
  return await getAppData(
    TAGS_APP,
    `/sources?${sources.join("&")}${
      params?.space ? `&space=${params.space}` : ""
    }`,
  )
}

export const createTag = async (tag: ITag): Promise<ITag> => {
  return await postAppData(TAGS_APP, tag, "")
}

export const updateTag = async (tag: ITag): Promise<ITag> => {
  return await putAppData(TAGS_APP, tag, `/tags/${tag.uid}`)
}

export const createTagGroup = async (
  group: Omit<ITagGroup, "uid">,
): Promise<ITagGroup> => {
  return await postAppData(TAGS_APP, group, "/groups")
}

export const assignTagToGroup = async (group: string, tags: string[]) => {
  await putAppData(TAGS_APP, { tags }, `/groups/${group}`)
}

export const addTagToSource = async (tag: string, sources: string[]) => {
  return await putAppData(TAGS_APP, { sources }, `/tags/${tag}/sources`)
}

export const unassignTagFromGroup = async (group: string, tag: string) => {
  return await deleteAppData(TAGS_APP, { tags: [tag] }, `/groups/${group}/tags`)
}

export const deleteTag = async (tag: string) => {
  return await deleteAppData(TAGS_APP, null, `/tags/${tag}`)
}

export const deleteTagGroup = async (group: string) => {
  return await deleteAppData(TAGS_APP, null, `/groups/${group}`)
}
