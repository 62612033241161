import { atom, noWait, selector, selectorFamily } from "recoil"
import { ICompany } from "../../types"
import { getCompanies } from "../../utils/api"

export let global_companies: ICompany[] = []

export const getGlobalCompanies = () => {
  if (global_companies.length === 0) {
    getCompanies().then((companies) => {
      global_companies = companies
    })
  }
  return global_companies
}

export const companiesAtom = selector({
  key: "companiesAtom",
  get: async ({ get }) => {
    const { contents: spaces, state } = get(noWait(_companiesAtom))

    if (state !== "hasValue") {
      return []
    }
    global_companies = spaces
    return spaces
  },
  set: ({ set }, newValue: ICompany[]) => {
    set(_companiesAtom, newValue as ICompany[])
    global_companies = newValue
  },
})

const _companiesAtom = atom<ICompany[]>({
  key: "companies",
  default: selector({
    key: "companies/default",
    get: async () => {
      const spaces = await getCompanies()
      return spaces
    },
  }),
})

export const companyAtom = selectorFamily({
  key: "company",
  get:
    (uid: string) =>
    ({ get }): ICompany | Record<string, any> => {
      const { contents: companies, state } = get(noWait(companiesAtom))

      if (state !== "hasValue") {
        return {}
      }

      return companies?.find((c) => c.uid === uid)
    },
})
