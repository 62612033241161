import { IUser } from "@/features/people/types"
import { fetcher } from "@/utils/api"
import { ICompany, IDealBody } from "../types"

/** COMPANIES */

export const addAvailableContactToCompany = async (
  company: string,
  contact: string,
) => {
  return await fetcher(`/companies/${company}/contacts/available`, {
    method: "POST",
    body: JSON.stringify({ contact }),
  })
}

export const getCompanies = async () => {
  return await fetcher(`/companies`)
}

export const updateCompanyStatus = async (company: string, status: string) => {
  return await fetcher(`/companies/${company}/status`, {
    method: "PUT",
    body: JSON.stringify({ status }),
  })
}

export const getAvailableContacts = async (company: string) => {
  return await fetcher(`/companies/${company}/contacts/available`)
}

export const uploadCompanyLogo = async (company: string, file: FormData) => {
  return await fetcher(`/companies/${company}/logo`, {
    method: "PUT",
    body: file,
  })
}

export const updateCompany = async (
  uid: string,
  company: Partial<ICompany>,
) => {
  return await fetcher(`/companies/${uid}`, {
    method: "PUT",
    body: JSON.stringify(company),
  })
}

export const deleteCompany = async (uid: string) => {
  return await fetcher(`/companies/${uid}`, {
    method: "DELETE",
  })
}

/** TASKS */

export const getCompanyTasks = async (company: string) => {
  return await fetcher(`/companies/${company}/tasks`)
}

/** DEALS */

export const getAllDeals = async () => {
  return await fetcher(`/deals`)
}

export const getCompanyDeals = async (company: string) => {
  return await fetcher(`/companies/${company}/deals`)
}

export const createDeal = async (deal: IDealBody) => {
  return await fetcher(`/deals`, {
    method: "POST",
    body: JSON.stringify({ deal }),
  })
}

export const updateDeal = async (uid: string, deal: IDealBody) => {
  return await fetcher(`/deals/${uid}`, {
    method: "PUT",
    body: JSON.stringify({ deal }),
  })
}

export const removeDeal = async (uid: string) => {
  return await fetcher(`/deals/${uid}`, {
    method: "DELETE",
  })
}

/** CONTACTS */

export const getCompanyContacts = async (company: string) => {
  return await fetcher(`/companies/${company}/contacts`)
}

export const createContact = async (
  company: string,
  contact: Partial<IUser>,
) => {
  return await fetcher(`/companies/${company}/contacts`, {
    method: "POST",
    body: JSON.stringify({ contact }),
  })
}

/** NOTES */

export const getNotes = async (source: string) => {
  return await fetcher(`/notes?source=${source}`)
}

export const createNote = async (text: string, source: string) => {
  return await fetcher(`/notes`, {
    method: "POST",
    body: JSON.stringify({ source, text }),
  })
}

export const updateNote = async (uid: string, text: string) => {
  return await fetcher(`/notes/${uid}`, {
    method: "PUT",
    body: JSON.stringify({ text }),
  })
}

export const deleteNote = async (uid: string) => {
  return await fetcher(`/notes/${uid}`, {
    method: "DELETE",
  })
}
