import { groupBy } from "lodash"
import { getTagsBySources as gtbs } from "./api"

export const getTagsBySources = async (
  sources: string[],
  params?: { space?: string },
) => {
  const tags = await gtbs(sources, params)

  return groupBy(tags, "source")
}
