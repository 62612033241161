import { selectedTask } from "@/atoms/tasks"
import {
  faArrowLeft,
  faTag,
  faTags,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RaceBy, Ring } from "@uiball/loaders"
import { Popover } from "antd"
import { useTagGroups, useTags } from "hooks/queries/tagQueries"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useRecoilValue } from "recoil"
import { toast } from "sonner"
import { TextInput } from "../../../shared/components/fields"
import { moveToNextTabIndexAfterElement } from "../../../utils/TabNavigationUtils"
import { TAGS_APP, putAppData } from "../../../utils/api/apps"
import { Icon } from "../../icons/components/IconLoader"
import { ITag } from "../types"

interface IAddTagButton {
  source?: string
  onSelect(tag: ITag): void
  children?: React.ReactNode
  group?: string
  open?: boolean
  onClose?(): void
  hoverEffect?: boolean
}

export const AddTagButton = ({
  source,
  onSelect,
  children,
  group,
  open = false,
  onClose,
  hoverEffect,
}: IAddTagButton) => {
  const [selectedGroup, setSelectedGroup] = useState(group)
  const [searchQuery, setSearchQuery] = useState(null)
  const [isOpen, setIsOpen] = useState(open)
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)
  const [isAddingTag, setIsAddingTag] = useState<Record<string, boolean>>({})
  const task = useRecoilValue(selectedTask)
  const taskOwner = task?._owner?.uid
  const { tags, isLoading: tagsIsLoading } = useTags(taskOwner)
  const { groups, isLoading: groupsIsLoading } = useTagGroups(taskOwner)

  useEffect(() => {
    if (group) {
      setSelectedGroup(group)
    }
  }, [group])

  const inputRef = useRef<HTMLInputElement>(null)
  const buttonRef = useRef(null)
  const isEventListenerAdded = useRef(false)

  const noGroupTags = useMemo(() => {
    return tags?.filter((tag) => tag.groups.length === 0)
  }, [tags])

  const filteredTags = useMemo(() => {
    let tempFilteredTags = tags

    if (selectedGroup === "other") {
      tempFilteredTags = noGroupTags
    } else if (selectedGroup) {
      tempFilteredTags = tempFilteredTags?.filter((tag) =>
        tag.groups.some((group) => group.uid === selectedGroup),
      )
    }

    if (searchQuery) {
      tempFilteredTags = tempFilteredTags?.filter(({ name }) =>
        name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(searchQuery.toLowerCase().replace(/\s+/g, "")),
      )
    }

    return tempFilteredTags
  }, [tags, selectedGroup, searchQuery])

  const addTag = async (tag: ITag) => {
    try {
      setIsAddingTag((prev) => ({ ...prev, [tag.uid]: true }))
      if (source)
        await putAppData(
          TAGS_APP,
          { sources: [source] },
          `/tags/${tag.uid}/sources`,
        )
      toast.success("Tag added")
      onSelect?.(tag)
    } catch (error) {
      toast.error("Something went wrong")
    } finally {
      setIsAddingTag((prev) => ({ ...prev, [tag.uid]: false }))
    }
  }

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === "Tab") {
        e.stopPropagation()
        e.preventDefault()
        moveToNextTabIndexAfterElement(buttonRef.current)
        return
      }

      if (e.code === "Escape") {
        e.preventDefault()
        e.stopPropagation()
        resetPopover()
        return
      }

      if (e.code === "KeyA" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        e.stopPropagation()
        inputRef.current?.focus()
        inputRef.current.select()
        return
      }

      inputRef.current?.focus()

      if (searchQuery === null && e.code === "Backspace") {
        setSelectedGroup(null)
        return
      }

      if (e.code === "ArrowDown") {
        e.preventDefault()
        setHighlightedIndex((prev) => (prev + 1) % filteredTags.length)
      } else if (e.code === "ArrowUp") {
        e.preventDefault()
        setHighlightedIndex(
          (prev) => (prev - 1 + filteredTags.length) % filteredTags.length,
        )
      } else if (e.code === "Enter") {
        e.preventDefault()
        if (filteredTags[highlightedIndex]) {
          addTag(filteredTags[highlightedIndex])
        } else if (searchQuery) {
          toast.error("No tag selected! something went wrong")
        }
      }
    },
    [filteredTags, highlightedIndex, addTag],
  )

  const addEventListener = () => {
    if (!isEventListenerAdded.current) {
      document.addEventListener("keydown", handleKeyPress)
      isEventListenerAdded.current = true
    }
  }

  const removeEventListener = () => {
    if (isEventListenerAdded.current) {
      document.removeEventListener("keydown", handleKeyPress)
      isEventListenerAdded.current = false
    }
  }

  useEffect(() => {
    if (isOpen) {
      addEventListener()
    } else {
      removeEventListener()
    }

    return () => {
      removeEventListener()
    }
  }, [isOpen, addEventListener, removeEventListener])

  const resetPopover = () => {
    setSearchQuery(null)
    setIsOpen(false)
    setSelectedGroup(group || null)
    setHighlightedIndex(-1)
    removeEventListener()
    onClose?.()
  }

  const toggleOpen = (open?: boolean) => {
    setIsOpen(open || !isOpen)
    if (!open) {
      resetPopover()
    } else {
      inputRef.current?.focus()
      addEventListener()
    }
  }

  useEffect(() => {
    toggleOpen(open)
  }, [open])

  useEffect(() => {
    return () => {
      removeEventListener()
    }
  }, [])

  if (tagsIsLoading || groupsIsLoading) return <RaceBy />

  const renderTags = () => {
    return filteredTags.map((tag, index) => {
      const isHighlighted = index === highlightedIndex
      return (
        <div
          key={tag.uid}
          className={`relative cursor-pointer select-none rounded-md p-2 px-2 text-xs capitalize`}
          onClick={() => addTag(tag)}
          style={{ background: tag.background_color, color: tag.text_color }}
        >
          {isAddingTag[tag.uid] ? (
            <Ring size={14} color={tag.text_color} />
          ) : (
            <p>{tag.name}</p>
          )}
          {isHighlighted && (
            <div className="text-green absolute right-2 top-1/2 flex h-4 w-4 -translate-y-1/2 items-center justify-center rounded-full bg-green-600 text-xs text-white">
              <Icon icon="fasCheck" />
            </div>
          )}
        </div>
      )
    })
  }

  return (
    <>
      <Popover
        placement="left"
        trigger="click"
        open={isOpen}
        onOpenChange={(open) => {
          toggleOpen(open)
        }}
        content={
          <>
            <div className="flex max-h-96 w-[15rem] flex-col justify-items-center gap-2 overflow-y-auto p-2 focus:outline-none">
              {selectedGroup && (
                <div
                  className="cursor-pointer pt-2 text-xs"
                  onClick={() => setSelectedGroup(null)}
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Back to Tag Group
                  overview
                </div>
              )}
              <p className="text-sm font-medium">List of your tags</p>
              <TextInput
                ref={inputRef}
                placeholder="Search for tags"
                className="text-xs"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                }}
              />
              <div className="h-auto max-h-96 overflow-y-auto">
                {!selectedGroup && !searchQuery && (
                  <>
                    {groups?.map((group) => {
                      return (
                        <div
                          key={group.uid}
                          onClick={() => {
                            setSelectedGroup(group.uid)
                            setSearchQuery(null)
                          }}
                          className="mb-2 flex w-full cursor-pointer items-center rounded-lg border p-2 capitalize hover:bg-slate-100"
                        >
                          <p className="flex-1 text-xs">{group.name}</p>
                          <div className="flex flex-shrink items-center gap-1 text-xs font-medium">
                            {group.uid === "other"
                              ? noGroupTags.length
                              : group.tags.length}
                            <FontAwesomeIcon icon={faTags} />
                          </div>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
              {(selectedGroup || searchQuery) && renderTags()}
            </div>
          </>
        }
      >
        {children ? (
          children
        ) : hoverEffect ? (
          <div
            className="flex min-h-[26px] min-w-[26px] cursor-pointer items-center justify-center rounded-full bg-blue-500 text-white"
            ref={buttonRef}
          >
            +
          </div>
        ) : (
          <div className="item-center flex hover:opacity-80" ref={buttonRef}>
            <div className="relative mr-1 flex cursor-pointer items-center rounded-xl bg-blue-500 px-4 py-2 text-white">
              <FontAwesomeIcon size="sm" icon={faTag} />
              <div className="ml-2 flex h-3 min-w-[4rem] items-center justify-center text-xs font-medium">
                + Add tag
              </div>
            </div>
          </div>
        )}
      </Popover>
    </>
  )
}
