import { cn } from "@/utils/helpers"
import { faXmark } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Popover } from "antd"
import { HTMLAttributes } from "react"
import { TAGS_APP, deleteAppData } from "../../../utils/api/apps"
import { Tag } from "./Tag"

type TagsViewerProps = HTMLAttributes<HTMLDivElement> & {
  tags?: Record<string, any>[]
  shownAmount?: number
  fullWidth?: boolean
  source?: string
  onRemove?: (uid?: string) => void
}

export const TagsViewer = ({
  tags,
  fullWidth = false,
  source,
  shownAmount = 1,
  onRemove,
  className = "",
  ...props
}: TagsViewerProps) => {
  const _tags = [...(tags || [])]
  const tagsLength = tags?.length

  const shownTags = _tags.slice(0, shownAmount)
  const popoverTags = _tags.slice(shownAmount)

  const removeTag = async (tag: string) => {
    if (source) {
      await deleteAppData(
        TAGS_APP,
        { sources: [source] },
        `/tags/${tag}/sources`,
      )
    }
    onRemove?.(tag)
  }

  const xMark = (uid: string) =>
    onRemove && (
      <div
        className="invisible absolute -right-1 -top-1 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-red-400 text-xs text-white group-hover:visible"
        onClick={() => removeTag(uid)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
    )

  const popoverContent = (
    <div className={`${!fullWidth && "flex flex-col gap-2"}`}>
      {popoverTags?.map((tag) => {
        return (
          <div key={tag.uid} className={`${!fullWidth && "relative"}`}>
            <Tag
              {...tag}
              className="w-fit cursor-default text-ellipsis whitespace-nowrap"
            >
              {tag.name}
            </Tag>
            {xMark(tag.uid)}
          </div>
        )
      })}
    </div>
  )

  const shownContent = shownTags
    .sort((tagA, tagB) => tagA?.name?.localeCompare(tagB.name)) // Sort tags alphabetically
    .map((tag) => (
      <div
        key={tag.uid}
        className={`${!fullWidth && "group relative col-span-2 flex max-w-[150px] flex-col gap-1"}`}
      >
        <Tag
          key={tag.uid}
          {...tag}
          className={`${!fullWidth && "w-fit cursor-default truncate text-ellipsis whitespace-nowrap"} w-full`}
        >
          {tag.name}
        </Tag>
        {xMark(tag.uid)}
      </div>
    ))

  if (tagsLength >= 1) {
    return (
      <div
        {...props}
        className={cn("flex w-full flex-wrap items-center gap-1", className)}
      >
        {shownContent}
        {tagsLength > shownAmount && (
          <Popover zIndex={500} placement={"right"} content={popoverContent}>
            <div className="cursor-pointer text-xs font-medium">
              +{popoverTags.length}
            </div>
          </Popover>
        )}
      </div>
    )
  }
}
