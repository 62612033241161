import { ITag } from "@/features/tags/types"
import { getTagGroups, getTags } from "@/features/tags/utils/api"
import { getTagsBySources } from "@/features/tags/utils/getTagsBySources"
import { useQuery, useQueryClient } from "@tanstack/react-query"

export const useTags = (space?: string) => {
  const { data: tags, ...rest } = useQuery({
    queryKey: ["tags", space],
    queryFn: () => getTags(space),
    initialData: [],
  })
  return { tags, ...rest }
}

export const useSourceTags = (source: string, opts?: { space?: string }) => {
  const { data, ...rest } = useQuery({
    queryKey: ["sourceTags", source],
    queryFn: () => getTagsBySources([source], { space: opts?.space }),
    initialData: {},
    enabled: !!source,
  })
  const tags = data?.[source] ?? []
  const queryClient = useQueryClient()

  const invalidateTags = () => {
    queryClient.invalidateQueries({
      queryKey: ["sourceTags", source],
    })
  }

  const setTags = (arg: ITag[] | ((old: ITag[]) => ITag[])) => {
    const updatedTags = typeof arg === "function" ? arg(tags) : arg
    queryClient.setQueryData(["sourceTags", source], updatedTags)
    invalidateTags()
  }

  return { tags, setTags, invalidateTags, ...rest }
}

export const useTagGroups = (space?: string) => {
  const { data: groups, ...rest } = useQuery({
    queryKey: ["tagGroups", space],
    queryFn: () => getTagGroups(space),
    initialData: [],
  })
  return { groups, ...rest }
}
