/**
 * Moves the focus to the next tabbable element after the current element.
 * @param currentElement The current HTML element
 */

export function moveToNextTabIndexAfterElement(
  currentElement: HTMLElement | null,
): void {
  if (!currentElement) return

  const focusableElements: HTMLElement[] = Array.from(
    document.querySelectorAll('[tabindex="0"]'),
  )

  const buttonPosition: number = Array.from(
    document.body.getElementsByTagName("*"),
  ).indexOf(currentElement)

  const nextElement: HTMLElement | undefined = focusableElements.find(
    (element) => {
      const elementPosition: number = Array.from(
        document.body.getElementsByTagName("*"),
      ).indexOf(element)
      return elementPosition > buttonPosition
    },
  )

  if (nextElement) {
    nextElement.focus()
  }
}
